nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: var(--bg); */
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    position: relative;
    z-index: 10;
    position: fixed !important;
    top: 0rem;
    width: 100% !important;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 0.5s;
    z-index: 999;
}

.p5 {
    padding: 5rem 1rem !important;
}
.user-info-login {
    display: none;
}

.nav-icon {
    width: 2rem;
    height: 2rem;
    display: none;
}

.sm-min {
    min-width: unset !important;
    width: fit-content;
}

nav img {
    height: 6rem;
    width: 5rem;
    object-fit: contain;
    position: relative;
}

.l-h {
  line-height: 0;
}

.links .active {
    position: relative;
    color: var(--primary);
    background: transparent;
}
.links .active::before {
  content: "";
  position: absolute;
  height: 4px;
  width: 100%;
  border-radius: 9px;
  bottom: -16px;
}


nav span {
    color: var(--primary);
    font-weight: bolder;
    font-size: 25px;
}

nav ul li {
    list-style: none;
}

.links {
    color: var(--text);
    font-weight: bold;
    transition: 0.5s ease-in-out;
    position: relative;
    padding: 1rem 3rem;
    justify-content: space-around;
    gap: 2rem;
}

.h-bg__nav {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
button {
    outline: none;
}

.menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    display: none;
  }
  
  .line {
    fill: none;
    stroke: var(--primary);
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .animate-toggle-nav .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .animate-toggle-nav .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .animate-toggle-nav .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }




  /* ============== */
  :root {
    --menu-width: 15em; /* Width of menu */
    --items: 4; /* Number of items you have */
    --item-width: calc(var(--menu-width) / var(--items));}
  #menu {
    width: fit-content;
    position: relative;
    z-index: 2;
    cursor: pointer;

    border-radius: 8px;
}
.select {
    color: var(--primary);
    box-shadow: #e8ffc1 0px 1px 2px 4px, #f2f2b1 0px 0px 0px 5px;
    border: 2px solid var(--primary) !important;
}

.input:focus, textarea:focus {
    box-shadow: #e8ffc1 0px 1px 2px 4px, #f2f2b1 0px 0px 0px 5px;
    border: 2px solid var(--primary) !important;
    
}

nav .comment-user-img {
    max-width: unset !important;
    font-size: unset;
}


.input {
    outline: none;
    height: 40px !important;
    border-radius: 7px;
    caret-color: var(--primary);
    color: var(--text);
    background: transparent;
    border: 2px solid var(--primary) !important;
}
input {
    direction: rtl;
}
#menu .menu-item {
  border-radius: 7px;
  transform-style: preserve-3d;
  flex-grow: 1;
  color: var(--text);
  flex-basis: var(--item-width);
  box-sizing: border-box;
  padding: 7px 1.5em;
  justify-content: center;
  perspective: 200px;
  /* background: var(--canceled); */
  background: var(--primary);
  color: white;
  
}



#menu .menu-text, #menu .menu-text a {
    font-size: 1em;
    text-decoration: none;
    text-shadow: 0 1px 5px rgba(0,0,0,0.1);
    transition: color 0.1s ease-out;
    text-align: center;
}


#sub-menu-holder {
    pointer-events: none;
    color: rgba(0,0,0,0.5);
    font-weight: normal;
    padding: 1em;
    position: absolute;
    transition: opacity 2 ease-out;
    transform: rotateX(-25deg) scale(1);
    transform-origin: 50% 7em 0em;
    opacity: 0;
    box-shadow: 0 2px 7px rgba(0,0,0,0.1), 0 2px 20px rgba(0,0,0,0.3);
    box-sizing: border-box;
    top: 3rem;
    border-radius: 10px;
    background: white;
    display: block;
    height: 300px;
    width: calc(var(--menu-width) * 1.5);
}

#sub-menu-container {
    position: absolute;
    z-index: -1;
    min-width: 100%;
    top: 2.5em;
    width: 100%;
}

#menu .menu-item.select ~ #sub-menu-container #sub-menu-holder {
    animation: clipPath 0.25s ease-out 1 forwards;        
    transition: clip-path 0.25s ease-out, left 0.15s ease-out, height 0.15s ease-out;
    opacity: 1;
    right: auto;
}
#menu .menu-item:nth-of-type(1).select ~ #sub-menu-container #sub-menu-holder,
#menu .menu-item:nth-of-type(4).select ~ #sub-menu-container #sub-menu-holder {
    clip-path: inset(0 28.75em 0 0 round 10px);
    height: 14em;
}

#menu .menu-item:nth-of-type(2):hover ~ #sub-menu-container #sub-menu-holder,
#menu .menu-item:nth-of-type(3):hover ~ #sub-menu-container #sub-menu-holder {
    clip-path: inset(0 15em 0 0 round 10px);
}

#menu .menu-item:nth-of-type(1):hover ~ #sub-menu-container #sub-menu-holder {
    left: calc(-9em + -1px);
}
#menu .menu-item:nth-of-type(2):hover ~ #sub-menu-container #sub-menu-holder {
    left: calc(-6.5em + -1px);
    height: 18.75em;
}
#menu .menu-item:nth-of-type(3):hover ~ #sub-menu-container #sub-menu-holder {
    left: calc(2.75em + 1px);
    height: 24.5em;
}
#menu .menu-item:nth-of-type(4):hover ~ #sub-menu-container #sub-menu-holder {
    left: calc(19em + 1px);
}

.menu-item .sub-menu {
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
  border: 2px solid var(--canceled);
  pointer-events: none;
  box-sizing: border-box;
  z-index: 999;
  margin-left: -5em;
  font-weight: initial;
  right: 0;
  padding: 7px 1em;
  bottom: 0px;
  transform: translate(15%, 110%);
  width: 20rem;
  display: flex;
  flex-direction: column;
  box-shadow: #e8ffc1 0px 1px 2px 4px, #f2f2b1 0px 0px 0px 5px;
    border: 2px solid var(--primary) !important;
  border-radius: 7px;
  color: var(--canceled);
}

.menu-item .icon-box:hover {
  border-radius: 7px;
  background-color: #dbdbdb;
  color: var(--canceled);
  transition: 0.5s;
}

.menu-item .sub-menu.double {
    min-width: 41.25em;
    height: 18.75em;
    display: grid;
    grid-template-columns: 54% 50%;
}
.menu-item .sub-menu.triple {
    min-width: 41.25em;
    height: 25em;
    display: grid;
    padding: 1.5em 2.5em;
    grid-template-columns: 55% 45%;
}
.menu-item.select .sub-menu {
    pointer-events: all;
}

/* .menu-text:after {
    transition: bottom 0.25s ease-out, opacity 0.01s ease-out 0.01s;
    opacity: 0;
    content: '';
    position: absolute;
    pointer-events: none;
    bottom: -5em;
    border-color: transparent transparent var(--canceled) transparent;
    border-width: 10px;
    border-style: solid;
} */
.menu-item.select .menu-text:after {
  bottom: -34px;
  opacity: 1;
  transition: bottom 0.25s ease-out, opacity 0.01s ease-out 0.15s;
  right: 10px;
}
.menu-item.select .sub-menu {
    opacity: 1;
    margin-left: 0;
}

.menu-item.select ~ #sub-menu-container #sub-menu-holder  {
    transition: transform 0.25s ease-out, opacity 0.25s ease-out, clip-path 0.25s ease-out;
}

@keyframes clipPath {
    0% {
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg) scale(1);
        top: 4.5em;
        opacity: 1;        
    }
}

/* Menu specific */
.menu-item .title {
  color: var(--canceled);
  font-size: 16px;

}

.menu-item .icon-box * {
    transition: all 0.1s ease-out;
    position: relative;
}
.menu-item .icon-box.space {
    margin-bottom: 1.25em;
}
.menu-item .icon-box.flat {height: 1.5em;margin-bottom: 0.5em;}
.menu-item .icon, .menu-item .text {
    color: #9ea9af;
}

.menu-item .icon {
    color: rgb(44 70 86);
    font-size: 1.25em;
    margin: 0 1em 0 0;
}

.menu-item .icon-box {
  cursor: pointer;
    /* height: 4em; */
    row-gap: 0;
    /* line-height: 1em; */
    direction: rtl;
    font-size: 1.5rem;
    color: var(--canceled);
    padding: 7px 13px;
}

.menu-item .icon-box .text i {
    clip-path: inset(0 100% 0 0);
    font-size: 0.75em;
    position: relative;
    top: -1px;
}

/* Base Icon Style */
.icon-box.gb .icon i:before {
    background: linear-gradient(45deg, #0c86ef, #0cef9b);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    padding-left: 1px;
}

/* Define Icon Colors */
.icon-box.gb.a .icon i:before {background-image: linear-gradient(45deg, #2ca7ff 35%, #31d078 75%);}
.icon-box.gb.b .icon i:before {background-image: linear-gradient(45deg, #f32b2b 35%, #efbe0c 75%);}
.icon-box.gb.c .icon i:before {background-image: linear-gradient(45deg, #7176ff 35%, #b26cea 75%);}
.icon-box.gb.d .icon i:before {background-image: linear-gradient(45deg, #79c76f 35%, #bed09b 75%);}
.icon-box.gb.e .icon i:before {background-image: linear-gradient(45deg, #6082ff 35%, #ff5dbe 75%);}
.icon-box.gb.f .icon i:before {background-image: linear-gradient(45deg, #808080 35%, #bbbbbb 75%);}

.menu-item .icon-box:hover .text i {
    clip-path: inset(0 0 0 0);
    transition: clip-path 0.1s ease-out;
}

.sub-text {
  font-size: 13px;
  color: var(--text);
}

.menu-item .icon-box:hover .icon, .menu-item .icon-box:hover .title, .menu-item .icon-box:hover .sub-text {
    color: rgb(89 110 123);
}

#sub-menu-bottom {
    background: #d4e3ea70;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 0.25s ease-out, height 0.1s ease-out;
    left: 0;
    width: 100%;
    height: 5em;
}
#menu .menu-item:nth-of-type(3).highlight:hover ~ #sub-menu-container #sub-menu-holder #sub-menu-bottom {
    height: 7.5em;
}
.bottom-container {
    grid-area: 4 / -3 / 4 / 3;
    padding-top: 1.5em;
    color: #3a5260;
    font-weight: 500;
}

.top-container {
    grid-area: 1 / -3 / 1 / 3;
    margin: 0 0 2em 0;
}

.bottom-container a {
    float: right;
    background: linear-gradient(90deg, #8ea4b166, #a3b5bf6b);
    padding: 0.5em 1em;
    border-radius: 100px;
    position: absolute;
    color: #2c4655bf;
    font-weight: bold;
    text-decoration: none;
    right: 1.5em;
    bottom: 1.5em;
}

.bottom-container a:hover {
    background: linear-gradient(90deg, #8ea4b136, #a3b5bf33);
}

.sub-menu h3 {
    font-size: 1em;
    text-transform: uppercase;
    color: rgb(44 70 86 / 68%);
    margin: 0.75em 0;
}

.sub-menu .box a {
    width: 100%;
    font-size: 1em;
    color: #7a8a94;
    text-decoration: none;
}

.sub-menu .box a:hover {
    color: rgb(44 70 86);
}

.box {
    margin: 0 0 3em 0;
}

.icon.big i {
    font-size: 1.25em;
    top: 0.2em;
}
#menu .menu-item.highlight.select ~ #sub-menu-container #sub-menu-holder #sub-menu-bottom { opacity: 1; }

#info-box {
    position: absolute;
    bottom: 2em;
    right: 2em;
}

#info-box ul {
    list-style: none;
}
#info-box a {
    color: white;
    text-decoration: none;
    text-align: right;
    padding: 0.5em 0;
    transition: all 0.1s ease-out;
    display: block;
    text-transform: uppercase;
    font-size: 1.25em;
    font-weight: 900;
}
#info-box a:hover {
    color: rgba(255,255,255,0.5);
}
