.profile {
    background-color: #f3f4f6;
}

.label-trans {
    background-color: var(--light-bg);
    padding: 0 5px;
    font-size: 14px;
    position: absolute;
    color: var(--pending);
    right: 1rem;
    top: 0;
    transform: translateY(-50%);
  }

  .profile-sidebar ul li a{
    font-weight: bold;
    transition: 0.5s;
    position: relative;
  }
  .profile-sidebar ul li a.active {
    font-weight: bold;
    color: var(--primary);
  }
  .profile-sidebar ul li a.active::before {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    background: var(--primary);
    right: -2rem;
  }
  .profile-sidebar ul li:hover {
    color: var(--primary);
  }

  .drop-container {
    position: relative;
    display: flex;
    flex: 1;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
    background: #eee;
}

.drop-container:hover {
    background-color: var(--pending-light);
    border-color: #111;
  }
  
  .drop-container:hover .drop-title {
    color: #222;
  }
  
  .drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }

  .adressbox {
    padding: 2rem;
    background: #eee;
    border: 2px solid #eee;
    transition: 0.5s;
    border-radius: 1rem;
    width: 46%;
    position: relative;
  }

  .poubelle {
    position: absolute;
    top: 1rem;
    left: 1rem;
    transition: 0.5s;
    cursor: pointer;
  }

  .poubelle:hover {
    color: var(--error);
  }
  .adressbox:hover{
    padding: 2rem;
    background: var(--pending-light);
    border: 2px solid #eee;
    transition: 0.5s;
  }

  .orders {
    height: 80vh;
    overflow: scroll;
  }

  .orders .order-box {
    background-color: #eee;
    border-radius: 7px;
    transition: 0.5s;
    border: 2px solid #eee;
    cursor: pointer;
  }

  .orders .order-box:hover {
    border: 2px solid var(--primary);
  }

  .order-indic {
    background: #eee;
  }

  .hh-grayBox {
    background-color: #F8F8F8;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow-x: scroll;
  }
  
  .trackcont {
    width: fit-content;
    overflow-x: scroll;
    display: flex;

  }

  .pt45{padding-top:45px;}
  .order-tracking{
    text-align: center;
    width: 10rem;
    position: relative;
    display: block;
  }
  .scrolable {
    width: 80vh;
    overflow-x: scroll;
  }
  .order-tracking .is-complete{
    display: block;
    position: relative;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 0px solid #AFAFAF;
    background-color: #f7be16;
    margin: 0 auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
    z-index: 2;
  }
  .order-tracking .is-complete:after {
    display: block;
    position: absolute;
    content: '';
    height: 14px;
    width: 7px;
    top: -2px;
    bottom: 0;
    left: 5px;
    margin: auto 0;
    border: 0px solid #AFAFAF;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  .order-tracking.completed .is-complete{
    border-color: #27aa80;
    border-width: 0px;
    background-color: #27aa80;
  }
  .order-tracking.completed .is-complete:after {
    border-color: #fff;
    border-width: 0px 3px 3px 0;
    width: 7px;
    left: 11px;
    opacity: 1;
  }
  .order-tracking p {
    color: #A4A4A4;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 20px;
  }
  .order-tracking p span{font-size: 14px;}
  .order-tracking.completed p{color: #000;}
  .order-tracking::before {
    content: '';
    display: block;
    height: 3px;
    width: calc(100% - 40px);
    background-color: #f7be16;
    top: 13px;
    position: absolute;
    right: calc(-50% + 20px);
    z-index: 0;
  }
  .order-tracking:first-child:before{display: none;}
  .order-tracking.completed:before{background-color: #27aa80;}

  .pending {
    color: var(--pending);
    background: var(--pending-light);
    
  }
  .paid {
    color: var(--success);
    background: var(--success-light);
    
  }
  .failed {
    color: var(--error);
    background: var(--error-light);
    
  }
  .status {
    padding: 1px 10px;
    font-weight: bold;
    font-size: 13px;
    border-radius: 7px;
  }


  .coupon {
    width: 350px;
    height: 150px;
    border-radius: 10px;
    overflow: hidden;
    filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5));
    display: flex;
    align-items: stretch;
    position: relative;
    text-transform: uppercase;
    direction: ltr;
  }
  .coupon::before,
  .coupon::after {
    content: "";
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
  }
  
  .coupon::before {
    left: 0;
    background-image: radial-gradient(
      circle at 0 50%,
      transparent 25px,
      gold 26px
    );
  }
  
  .coupon::after {
    right: 0;
    background-image: radial-gradient(
      circle at 100% 50%,
      transparent 25px,
      gold 26px
    );
  }
  
  .coupon > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .left-cop {
    width: 20%;
    border-right: 2px dashed rgba(0, 0, 0, 0.13);
  }
  .left-cop div {
    transform: rotate(-90deg);
    white-space: nowrap;
    font-weight: bold;
    font-size: 10px;
    margin-right: -28px;
  }
  
  .center-cop {
    flex-grow: 1;
    text-align: center;
  }
  
  .right-cop {
    width: 120px;
    background-image: radial-gradient(
      circle at 100% 50%,
      transparent 25px,
      #fff 26px
    );
  }
  .right-cop div {
    font-family: "Libre Barcode 128 Text", cursive !important ;
    font-size: 2.5rem;
    font-weight: 400;
    transform: rotate(-90deg);
    color: black !important;
  }
    html {
      scroll-behavior: smooth;
  }
  
  .center-cop h2 {
    background: #000;
    color: gold;
    padding: 0 10px;
    font-size: 1.15rem;
    white-space: nowrap;
  }
  .soon-cp::before{

  }

  .expired-cp *{
    color: white;
  }

  .soon-cp::before{
    background-image: radial-gradient(circle at 0 50%, transparent 25px, var(--pending) 26px);
  }
  .soon-cp::after {
    right: 0;
    background-image: radial-gradient(circle at 100% 50%, transparent 25px, var(--pending) 26px);
}
  .expired-cp::before{
    background-image: radial-gradient(circle at 0 50%, transparent 25px, var(--error) 26px);
  }
  .expired-cp::after {
    right: 0;
    background-image: radial-gradient(circle at 100% 50%, transparent 25px, var(--error) 26px);
}
  .available-cp::before{
    background-image: radial-gradient(circle at 0 50%, transparent 25px, var(--success) 26px);
  }
  .available-cp::after {
    right: 0;
    background-image: radial-gradient(circle at 100% 50%, transparent 25px, var(--success) 26px);
}
  
  .center-cop h3 {
    font-size: 1.15rem;
  }
  .center-cop small {
    font-size: 0.625rem;
    font-weight: 600;
  }

  
