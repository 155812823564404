.form {
    padding: 0px 10px 0px 10px;
    background-color: #fff;
    width: 70vh;
}
.active .login {
    margin-left: -50%;
    opacity: 0;
    transition: margin-left 0.18s ease, opacity 0.15s ease;
}

.active .signup {
    opacity: 1;
}
.active .forms {
    height: 600px;
}
.form .title {
    position: relative;
    font-size: 27px;
    font-weight: 600;
}
.form .title::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background-color: var(--pending);
    border-radius: 25px;
}
.form .input-field {
    position: relative;
    height: 50px;
    width: 100%;
    margin-top: 30px;
}
.input-field input {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0 35px;
    border: none;
    outline: none;
    font-size: 16px;
    border-bottom: 2px solid #ccc;
    border-top: 2px solid transparent;
    transition: all 0.2s ease;
}
.input-field input:focus {
    border-bottom-color: var(--pending);
    background-color: var(--pending-light);
}
.input-field i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 23px;
    transition: all 0.2s ease;
}
.input-field input:is(:focus, :valid)~i {
    color: var(--pending);
}
.input-field i.icon {
    left: 0;
}
.input-field i.showHidePw {
    right: 0;
    cursor: pointer;
    padding: 10px;
}
.form .checkbox-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.checkbox-text .checkbox-content {
    display: flex;
    align-items: center;
}
.checkbox-content input {
    margin-right: 10px;
    accent-color: var(--pending);
}
.form .text {
    color: #333;
    font-size: 14px;
}
.form a.text {
    color: var(--pending);
    text-decoration: none;
}
.form a:hover {
    text-decoration: underline;
}
.form .button {
    margin-top: 35px;
}
.form .button input {
    border: none;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    border-radius: 6px;
    background-color: var(--pending);
    cursor: pointer;
    transition: all 0.3s ease;
}
.button input:hover {
    background-color: rgb(57, 57, 14);
}
.form .login-signup {
    margin-top: 30px;
    text-align: center;
}

.login-with-google-btn {
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
    width: 100%;
  }
  .login-with-google-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }
  .login-with-google-btn:active {
    background-color: #eeeeee;
  }
  .login-with-google-btn:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
  }

.filled {
    position: relative;
    transition: 0.5s height ease;
}

.indic {
    position: relative;
    transition: 0.5s;
}

.title2 {
    font-weight: bold;
    font-size: 20px;
}
.filled .title2{
    color: var(--canceled);
}

.filledindic {
    position: absolute;
    width: 4px;
    height: 0%;
    background: var(--success);
    transition: 0.5s;
    top: 15%;
    border-radius: 7px;
}
.filled .filledindic {
    height: 90% !important;
}

.mandoub {
    padding: 7px;
    border: 2px solid #cdcdcd;
    width: 100%;
    border-radius: 1rem;
}

.mandoub-img {
    width: 10rem;
    height: 10rem;
}

.mandoub-img img{
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.med {
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--text);
}

.med svg {
    transition: 0.5s;
    cursor: pointer;
}
.med svg:hover {
    color: var(--canceled);
}

.link-sec {
    color: var(--pending);
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
    cursor: pointer;
}

.dnone {
    display: none;
}