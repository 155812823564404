.cart {
    border: 2px solid var(--primary);
    width: 50%;
    height: 90vh;
    position: fixed;
    top: 50%;
    background: var(--bg);
    z-index: 1000;
    transform: translate(100%, -50%);
    padding: 1rem;
    right: 0;
    transition: 0.5s;
    border-radius: 1rem;
}

.cart.toggled {
    transform: translate(0,-50%) !important;
}

.men {
    color: var(--text);
    background-color: var(--pending-light);
    padding: 7px 1rem;
}

.prodPr {
    color: var(--canceled);
    font-weight: 700;

}

.gifted {
    position: absolute;
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    z-index: 4;
    top: 0;
    right: -18px;
}

.rate {
    padding: 2px 10px;
    border-radius: 13px;
    background-color: var(--pending);
    font-weight: 900;
    color: white;
    font-size: 15px;
}
.bold-1 {
    font-weight: bold;
}

.bg-success {
    background-color: var(--shipped-light);
}

.mandoub {
    padding: 7px 7px 7px 7px !important;
}

.cov-slide {
position: absolute;
top: 0;
left: 0;
height: 36% !important;
width: 100% !important;
border-radius: 24px 24px 0 0 !important;}

.med {

    right: 0;
    color: var(--text);
}

.col-err {
    font-weight: bold;
    color: var(--error);
}
.col-succ {
    font-weight: bold;
    color: var(--pending);
}
.typewriter {
    color: var(--primary);
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-left: .15em solid var(--primary); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */

    animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--primary); }
  }
.product-cart {
    border-radius: 1rem;
}

.rate svg {
    font-size: 18px !important;
}

.desc-cart {
    font-size: 12px;
    color: var(--text);
    height: 2.5rem;
    overflow: hidden;                /* Required for text-overflow */
    display: -webkit-box;            /* Enables line clamping */
    -webkit-line-clamp: 2;           /* Number of lines before truncation */
    -webkit-box-orient: vertical;    /* Sets box orientation to vertical */
}

.but {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contr {
    background: var(--canceled);
    /* padding: 0px 9px; */
    border-radius: 7px;
    /* line-height: 2; */
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: inline-block; */
}
.contr span{
    color: white;
    /* background: red; */
    width: 100%;
    line-height: -1;
    font-size: 18px;
    cursor: pointer;
}

.toggler {
    overflow: visible;
    width: 5rem;
    height: 5rem;
    background: var(--pending);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: white;
    
    border: 2px dashed var(--primary);
    cursor: pointer;

    /* Refined nonagon clip-path */
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
}

.holder {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-79%, -50%);
    z-index: 1001;
}

.indicat {
    position: absolute;
    background: #ff5050;
    font-size: 11px;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    line-height: 2;
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    top: 0;
    left: 0;
    z-index: 1;
    color: wheat;
    font-weight: bold;
    transform: translate(-43%, -50%);
}




.toggler svg{

}

.cardcontent {
    height: 90%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    gap: 2rem;
    padding: 2rem 1rem 7px;
}

.product-cart {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.details-cart-prod {

}

.product-cart-img {
    width: 7rem;
    height: 7rem;
    object-fit: contain;
    border-radius: 1rem;
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%) rotate(27deg);
}