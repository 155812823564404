#footer {
  background: #ebd0b3;
  padding: 10rem 3rem 3rem;
  color: var(--brown);
}

.wv {
    position: absolute;
    right: 0;
    width: 100%;
    top: 0;
    height: 18rem;
}
#footer2{
      background-image: url(https://img.freepik.com/free-vector/honey-background-yellow-color_1053-119.jpg?uid=R149959751&ga=GA1.1.1947062149.1730745968&semt=ais_hybrid);
    background: #f7f7f7;
      padding: 3rem;
      margin-top: 0px;
    /* padding-top: 5rem; */
    padding-top: 7rem;
      padding-bottom: 80px;
  }
  .logo-footer{
    /* max-width: 300px; */
  }
  .social-links{
    /* display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; */
  
  }
  .social-links h2{
    padding-bottom: 15px;
    font-size: 20px;
      font-weight: 600;
  }
  .social-links img{
    padding-bottom: 25px;
  }
  .social-icons{
    /* display: flex;
      gap: 3rem; */
    display: flex;
      flex-direction: column;
      gap: 1rem;
    color: #777777;
  }
  .social-icons a{
    /* font-size: 18px; */
      /* background: #ffffff; */
      /* box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
      padding: 0.4rem 1rem 0.4rem 1rem;
      border-radius: 3px;
    color: #82074a; */
    /* margin-right: 18px; */
    color: #777777;
  }
  .social-icons a:hover{
    color: #000;
  }
  .social-icons a i{
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
      padding: 0.4rem 1rem 0.4rem 1rem;
      border-radius: 3px;
      color: #82074a;
    font-size: 16px;
    margin-right: 12px;
  }
  li{
    list-style: none;
  }
  .useful-link h2{
    padding-bottom: 15px;
    font-size: 20px;
      font-weight: 600;
  }
  .useful-link img{
    padding-bottom: 15px;
  }
  .use-links{
    line-height: 32px;
  }
  .use-links li i{
    font-size: 14px;
      padding-right: 8px;
      color: #898989;
  }
  .use-links li a{
    color: #303030;
      font-size: 15px;
      font-weight: 500;
    color: #777777;
  }
  .use-links li a:hover{
    color: #000;
  }
  .address h2{
    padding-bottom: 15px;
    font-size: 20px;
      font-weight: 600;
  }
  .address img{
    padding-bottom: 15px;
  }
  .address-links li a{
    color: #303030;
      font-size: 15px;
      font-weight: 500;
    color: #777777;
  
  }
  .address-links li i{
    font-size: 16px;
      padding-right: 8px;
    color: #82074a;
  
  }
  .address-links li i:nth-child(1){
    padding-top: 9px;
  }
  .address-links .address1{
    font-weight: 500;
      font-size: 15px;
    display: flex;
  }
  .address-links{
        line-height: 32px;
      color: #777777;
  }
  .copy-right-sec{
    padding: 1.8rem;
      color: var(--text);
      text-align: center;
  }
  .copy-right-sec a{
    color: #fcd462;
      font-weight: 500;
  }
  a{
    text-decoration:none;
  }