.wrapper {
    max-width: 75%;
    margin: auto;
  }

  .qaf-p {
    background: #fff8b7;
    height: 20vh;
  }

  .fa-chevron-down {
    transition: 0.5s;
  }
  
  .wrapper > p,
  .wrapper > h1 {
    margin: 1.5rem 0;
    text-align: center;
  }
  
 
  .accordion {
    background-color: white;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    font-size: 1.2rem;
    width: 100%;
    padding: 2rem 2.5rem;
    border: none;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    transition: 0.5s;
  }
  
  .accordion i {
    font-size: 1.6rem;
  }
  
  .active,
  .accordion:hover {
    background-color: #f1f7f5;
  }
  .pannel {
    background-color: white;
    background-color: var(--pending-light);
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .pannel p {
    width: 90%;
    margin: 1rem auto;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.2rem;
    line-height: 1.4;
  }
  
  .faq {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 10px 0;
  }
  .faq.active, .accordion.active {
    background-color: var(--pending-light);
    border: none;
  }

  .rotate {
    transform: rotate(90deg);
  }



 
  
  #container input {
    line-height: normal;
    border-radius: 0;
  }
  
  #container textarea {
    overflow: auto;
  }
  #container textarea:focus {
    box-shadow:unset;
    transition: 0s !important;
    border: 1px solid !important;
    outline: none !important;
  }
  
  #container {
    background: white;
    border-radius: 1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    width: 50%;
    padding: 2rem;
  }

  .client-info {
    width: 30%;
    border-radius: 1rem;
    
    padding: 3rem 1rem;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .incl {
    transform: rotate(-20deg);
  }

  .client-info img {
    width: 100%;
    height: 40%;
    object-fit: cover;
  }

  #container .menu-item.select .sub-menu  {
    width: 100%;
  }

  #container .menu-item {
    border-radius: 9px;

    margin-bottom: 1rem;
  }

  #container .menu-text {
    padding: 1rem;
    height: 2.5rem;
    border-radius: 7px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary);
  }
  
  .client-info> .df-c {
    justify-content: space-between !important;
    height: 100%;
  }
  
  #container form {
    padding: 37.5px;
  }
  
  #container h1 {
    color: #474544;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
  
  #container .underline {
    border-bottom: solid 2px #474544;
    margin: -0.512em auto;
    width: 80px;
  }
  
  #container .icon_wrapper {
    margin: 50px auto 0;
    width: 100%;
  }
  
  #container .icon {
    display: block;
    fill: #474544;
    height: 50px;
    margin: 0 auto;
    width: 50px;
  }
  
  #container .email {
    float: right;
    width: 45%;
  }
  
  #container input[type='text'],#container [type='email'],#container select,#container textarea {
    background: none;
    border: none;
    border-bottom: solid 2px #474544;
    color: #474544;
    font-size: 1.000em;
    font-weight: 400;
    margin: 0em 0 1.875em 0;
    padding: 0 0 0.875em 0;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  #container input[type='text']:focus,#container  [type='email']:focus,#container textarea:focus {
    outline: none;
    padding: 0 0 0.875em 0;
  }
  
  #container .message {
    float: none;
  }
  
  #container .name {
    float: left;
    width: 45%;
  }
  
  #container select {
    background: url('https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-down-32.png') no-repeat left;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  
  #container select::-ms-expand {
    display: none;
  }
  
  #container .subject {
    width: 100%;
  }
  
  #container .telephone {
    width: 100%;
  }
  
  #container textarea {
    line-height: 150%;
    height: 150px;
    resize: none;
    width: 100%;
  }
  
  #container ::-webkit-input-placeholder {
    color: #474544;
  }
  
  #container :-moz-placeholder { 
    color: #474544;
    opacity: 1;
  }
  
  #container ::-moz-placeholder {
    color: #474544;
    opacity: 1;
  }
  
  #container :-ms-input-placeholder {
    color: #474544;
  }
  
  #container #form_button {
    background: none;
    border: solid 2px #474544;
    color: #474544;
    cursor: pointer;
    display: inline-block;
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 0.875em;
    font-weight: bold;
    outline: none;
    padding: 20px 35px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .prod-logo {
    position: absolute;
    top: 0;
    width: 80%;
    height: 80%;
    object-fit: fill;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  #form_button:hover {
    background: #474544;
    color: #F2F3EB;
  }
  
  @media screen and (max-width: 768px) {
    #container {
      margin: 0px auto;
      width: 95%;
    }
  }
  
  @media screen and (max-width: 480px) {
    #container h1 {
      font-size: 26px;
    }
    
    #container .underline {
      width: 68px;
    }
    
    #container #form_button {
      padding: 15px 25px;
    }
  }
  
  @media screen and (max-width: 420px) {
    #container h1 {
      font-size: 18px;
    }

    #container {
      width: 100%;
    }

    
    #container .underline {
      width: 53px;
    }
    
    #container input[type='text'], [type='email'], select, textarea {
      font-size: 0.875em;
    }
  }
  