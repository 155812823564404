.container-prod {
  position: relative;
  width: 30%;
  padding: 1rem;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}
.container-prodp {
  font-size: 2em;
  color: var(--primary);
  font-weight: bold;
  letter-spacing: 1px;
}

.product-details p {
  font-size: 18px !important;
  line-height: 30px;
}

.color {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 2px solid white;
  background-color: blueviolet;
  cursor: pointer;
}

.productCard_block .size {
  padding: 7px;
}
.size {
  cursor: pointer;
}

.color.selected-col {
  width: calc(1.5rem + 3px);
  height: calc(1.5rem + 3px);
  border: 3px solid var(--primary);
}

.container-prod  h1 {
  font-size: 1.2em;
  color: #4E4E4E;
  margin-top: -5px;
  font-weight: bolder;
}

.container-prod  h2 {
  color: var(--primary);
  font-weight: bold;
  margin-top: -5px;
  font-size: 1.4rem;
}

.container-prod img {
  width: 173px;
  height: 135px;
  object-fit: contain;
  margin: auto;
}

.container-prod  .slideshow-buttons {
  top: 70%;
  display: none;
}

.container-prod  .one, .two, .three, .four {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #E0C9CB;
}

.container-prod  .one {
  left: 22%;
}

.container-prod  .two {
  left: 27%;
}

.container-prod  .three {
  left: 32%;
}

.container-prod  .four {
  left: 37%;
}

.w-col {
  width: 31% ;
}

.container-prod  .product {
  flex: 1;
  display: flex;
  width: unset;
  flex-direction: column;
  justify-content: space-between;
}

.container-prod  .desc {
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 17px;
  color: #4E4E4E;
  font-size: 0.7em;
  line-height: 1.6em;
  text-align: justify;
}

.container-prod  button:hover {
  background: #BA7E7E;
  transition: all 0.4s ease-in-out;
}

.container-prod  .add {
  width: 67%;
}

.container-prod  .like {
  width: 22%;
}

.jc-center {
  justify-content: center;
  margin-top: 5px;
}

.container-prod  .sizes:not(.sizes.df.w-full.jc-center) {
  display: grid;
  color: var(--primary);
  grid-template-columns: repeat(auto-fill, 30px);
  width: 100%;
  grid-gap: 4px;
  margin-left: 20px;
  margin-top: 5px;
}
.container-prod  .sizes:hover {
  cursor: pointer;
}

.container-prod  .pick {
  margin-top: 11px;
  margin-bottom: 0;
  margin-left: 20px;
}

.size {
  padding: 9px 0px;
  text-align: center;
  border: 1px solid #ffe88d;
  font-size: 0.7em;
  text-align: center;
}
.cart .size {
  padding: 9px 7px;
  text-align: center;
  border: 1px solid #ffe88d;
  font-size: 0.7em;
  text-align: center;
  font-weight: bold;
}
.container-prod  .size:hover {
  background: var(--primary);
  color: #F5F5F5;
  transition: all 0.4s ease-in-out;
}
.size.selected-s {
  background: var(--primary);
  color: #F5F5F5;
  transition: all 0.4s ease-in-out;
}

.block_informationAboutDevice {
  padding-bottom: 5px;
}

.container-prod  .focus {
  background: #BA7E7E;
  color: #F5F5F5;
}









.mainWrapper {
  position: relative;
  width: 100%;
  min-width: 320px;
  padding: 0;
  margin: 0 !important;
}

.wrappen_block {
  position: relative;
  width: 100%;
  height: 100%;
}

.mainBackground {
  width: 100%;
}

.productCard_block {
  position: relative;
  margin: 0px auto 0;
  width: 80%;
  box-shadow: 0 5px 21px rgba(0, 0, 0, 0.08);
  background-color: #fff;
}

/* LEFT SIDE */
.productCard_leftSide {
  position: relative;
  width: 100%;
}

.productCard_brendBlock {
  width: 100%;
  height: 75px;
  position: absolute;
  top: 0;
  right: 0;
  max-width: 184px;
  max-height: 75px;
  z-index: 2;
}
.productCard_brendBlock__imageBlock {
  display: block;
  height: 100%;
}
.productCard_brendBlock__imageBlock img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sliderBlock {
  position: relative;
  height: 100%;
  margin-top: 89px;
  margin-bottom: 46px;
}

.sliderBlock_items {
  position: relative;
  display: block;
  width: 100%;
}
.sliderBlock_items__itemPhoto {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  transition: opacity 1s;
}
.sliderBlock_items__showing {
  opacity: 1;
  z-index: 3;
}
.sliderBlock_items:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: url("https://github.com/BlackStar1991/CardProduct/blob/master/app/img/decorElements/DecorRectangle.png") 50% 50% no-repeat;
  opacity: 0.2;
  z-index: 4;
}

.sliderBlock_controls {
  width: 100%;
}
.sliderBlock_controls__navigatin {
  margin-top: 8px;
  width: 100%;
}
.sliderBlock_controls__wrapper {
  margin: 1% auto;
  width: 110px;
  height: 20px;
}
.sliderBlock_controls__arrow {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 1px solid #536dfe;
  transition: 300ms all;
  background-color: #0d2660;
  border-radius: 50%;
}
.sliderBlock_controls__arrow:hover {
  background-color: #536dfe;
}
.sliderBlock_controls__arrow i {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  right: 50%;
  margin-left: -50%;
  margin-top: -50%;
  color: #fff;
  transition: 300ms all;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}
.sliderBlock_controls__arrowBackward {
  float: left;
}
.sliderBlock_controls__arrowForward {
  float: right;
}

.sliderBlock_positionControls {
  display: block;
  width: 100%;
  height: 3px;
  margin: 30px auto 0;
}
.sliderBlock_positionControls__paginatorItem {
  float: left;
  width: 18px;
  height: 10px;
  margin-left: 5px;
  background-color: #d7d7d7;
  cursor: pointer;
}
.sliderBlock_positionControls__paginatorItem:first-child {
  margin-right: 31%;
}
.sliderBlock_positionControls__active {
  background-color: #536dfe;
}

/*   RIGHT SIDE */
.productCard_rightSide {
  position: relative;
  padding-top: 34px;
  padding-right: 27px;
  padding-left: 40px;
  width: 100%;
}

.block_specification {
  float: left;
  margin-top: 8px;
  cursor: pointer;
  color: #536dfe;
  transition: all 0.5s;
}
.block_specification__button {
  display: inline-block;
  margin-right: 7px;
  height: 100%;
  font-size: 17px;
  text-transform: uppercase;
  transform: rotate(0);
  transition: transform 0.5s;
  transform-origin: center center;
}
.block_specification:hover .block_specification__button__rotate {
  transition: transform 0.5s;
  transform: rotate(1turn);
}
.block_specification:hover {
  color: #f1425d;
}

.block_specification__text {
  width: 30px;
  height: 11px;
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.block_model {
  display: inline-block;
  color: #263238;
  font-size: 12px;
  opacity: 0.9;
}

.block_name {
  width: 100%;
  color: #263238;
  font-weight: 400;
  line-height: 35px;
}
.block_name__mainName {
  margin: 0;
  padding: 0;
  font-size: 29px;
}
.block_name__addName {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

.block_product {
  position: relative;
  width: 100%;
}
.block_product__advantagesProduct {
  width: 271px;
  height: 41px;
  margin-top: 14px;
  color: #646b6f;
  font-size: 17px;
  line-height: 24px;
}
.block_product__link {
  line-height: 23px;
  color: #536dfe;
}

.block_informationAboutDevice {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.block_descriptionInformation {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 31px;
  color: #263238;
  font-size: 18px;
}

.block_descriptionCharacteristic {
  position: absolute;
  top: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  z-index: 30;
  transition: right, ease-out, 0.5s;
}
.block_descriptionCharacteristic__active {
  right: 0;
}

@keyframes moveCharackeristic {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
.block_specificationInformation_table {
  position: relative;
  height: 90%;
  background-color: #fff;
}
.block_specificationInformation_table tr {
  border-collapse: collapse;
  border: 1px solid #000;
}
.block_specificationInformation_table th {
  border: 1px solid #000;
  font-weight: bold;
}
.block_specificationInformation_table td {
  border-collapse: collapse;
  text-align: center;
  border: 1px solid #000;
}

.block_rating {
  width: 100%;
  margin-top: 33px;
}

fieldset,
label {
  margin: 0;
  padding: 0;
}

.block_rating__stars {
  border: none;
  float: left;
  width: auto;
  margin: 0;
  padding: 0;
}

input[type=checkbox] + label,
input[type=radio] + label {
  margin: 0;
}

.block_rating__stars > input {
  display: none;
}

.block_rating__stars > label:before {
  margin: 5px;
  font-size: 15px;
  font-family: "FontAwesome";
  display: inline-block;
  content: "\f005";
}

.block_rating__stars > .half:before {
  content: "\f089";
  position: absolute;
}

.block_rating__stars > label {
  color: #c9c9c9;
  float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/
.block_rating__stars > input:checked ~ label,
.block_rating__stars:not(:checked) > label:hover,
.block_rating__stars:not(:checked) > label:hover ~ label {
  color: #fccf47;
}

/* hover previous stars in list */
.block_rating__stars > input:checked + label:hover,
.block_rating__stars > input:checked ~ label:hover,
.block_rating__stars > label:hover ~ input:checked ~ label,
.block_rating__stars > input:checked ~ label:hover ~ label {
  color: #ffed85;
}

.block_rating__avarage {
  margin-right: 31px;
  color: #f1425d;
  font-size: 15px;
  line-height: 35px;
}

.block_rating__reviews {
  margin-right: 6px;
  color: #656c6f;
  font-size: 13px;
}

.block_price {
  margin-top: 29px;
  width: 100%;
}
.block_price__currency {
  padding: 0;
  margin: 0;
  vertical-align: top;
  color: #f1425d;
  font-size: 26px;
  font-weight: 600;
}
.block_price__shipping {
  padding: 0;
  margin: 0;
  color: #a2a9ad;
  font-size: 12px;
}

.radio_button {
  position: absolute;
  opacity: 0;
}

.block_goodColor {
  margin-top: 29px;
  width: 100%;
}
.block_goodColor__allColors {
  width: 100%;
  margin-top: 14px;
}
.block_goodColor__radio {
  display: inline-block;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  margin-right: 10% !important;
  background-color: #e8e6e3;
  cursor: pointer;
}

.radio_button:checked + .block_goodColor__radio {
  width: 25px;
  height: 25px;
  box-shadow: 0 0 5px 3px rgba(83, 109, 254, 0.5);
  border: 2px solid #fff;
}

.block_goodColor__black {
  background-color: #3c383a;
}

.block_goodColor__silver {
  background-color: silver;
}

.text_specification {
  padding: 0;
  color: #37474f;
  font-size: 13px;
}

.block_quantity {
  position: relative;
  height: 46px;
  margin-top: 45px;
}
.block_quantity span {
  display: inline-block;
  float: right;
  margin-top: 15px;
  margin-right: 10px;
}
.block_quantity__chooseBlock {
  vertical-align: top;
  height: 100%;
  margin: 0;
  padding: 0;
}
.block_quantity__number {
  display: inline-block;
  float: right;
  width: 52px;
  height: 46px;
  border: 1px solid #ddd;
  font-size: 18px;
}
.block_quantity__number::placeholder {
  color: #403f40;
  font-size: 18px;
  line-height: 35px;
}
.block_quantity__button {
  display: inline-block;
  float: right;
  width: 20px;
  height: 50%;
  margin: 0;
  padding: 0;
  margin-right: 11px;
  font-size: 0;
  cursor: pointer;
  font-size: 15px;
}
.block_quantity__button:hover, .block_quantity__button:focus {
  background-color: transparent;
}
.block_quantity__button:hover:before, .block_quantity__button:focus:before {
  color: #536dfe;
}


.button.button_addToCard {
  width: 147px;
  height: 46px;
  margin-top: 43px;
  margin-bottom: 0;
  padding: 0;
  color: #fff;
  background-color: #536dfe;
  font-size: 17px;
  transition: all 0.3s;
}
.button.button_addToCard:hover {
  background-color: #0d2660;
}

.aboutMe a {
  display: block;
  width: 30%;
  position: fixed;
  bottom: 0.1%;
  right: 20px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.5em;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}