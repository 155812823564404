.App {
    padding-top: 7rem;
}
@media only screen and (max-width: 900px) {
    nav {
        position: relative;
        width: 100% !important;
        top: 0 !important;
        padding: 1rem !important;
        gap: 2rem !important;
        
    }
    
    .product {
        width: 100%;
    }
    #menu .menu-text, #menu .menu-text a {
        font-size: 1em;
        text-decoration: none;
        text-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
        transition: color 0.1s ease-out;
        text-align: center;
        font-size: 14px;
    }

    .tr-{
        transform: unset;
        
    }
    
    .tr {
        transform: unset !important;

    }
    
    .scale {
        transform: unset;
    }

    nav > .\!w-fit.df.comment-user-img{
        display: none;
    }

    .ad-slide {
        padding-right: 0 !important;
        gap: 1rem !important;
    }

    .ad-slide .simple-btn {
        width: 85%;
    }
    
    .ad-sec {
        padding: 0rem;
    }
    
    .ad-slide p {
        text-align: center;
        font-size: 15px;

    }

    .contt {
        width: 85%;
    }

    .img-ad-cont {
        width: 55%;
        height: 14rem;
    }

    .shape1  {
        width: 3rem;
        height: 3rem;
    }

    .modal {
        max-width: 90% !important;
        min-width: 90%;
        padding: 1rem;
    }
    nav > img {
        display: none;
    }
    .user-info-login {
        display: flex;
    }

    nav .links .comment-user-img img{
        border-radius: 50%;
        width: 2rem !important;
        height: 2rem !important;
    }

    .links .nav-icon{
        display: flex;
        align-items: center;
        font-size: 22px;
        justify-content: center;
    }

    nav .links {
        gap: 1rem !important;
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        flex-direction: column;
        width: 101%;
        right: 0;
        align-items: flex-start;
        padding: 1rem 2rem;
        opacity: 0;
    }
    nav.nav-open {
        height: 40%;
        align-items: flex-start;
    }

    .menu {
        display: block;
    }
    .links.opened {
        transform: translateY(27%) !important;
        opacity: 1;
    }
    .sdnone {
        display: none;
    }
    .df.jc-sb.sm-df-c.sm-w-full.mt-16 {
        margin: 1rem 0rem !important;
        gap: 1rem !important;
    }
    .options {
        flex-direction: column;
    }

    .top-sellings {
        margin-bottom: 9rem;
    }
    .top-sellings .swiper-slide .product-img .prod:not(.swiper-slide-active .product-img .prod) {
        opacity: 0;
    }

    .top-sellings .swiper-slide-next, .top-sellings .swiper-slide-prev {
        width: 0% !important;
        margin: 0 !important;
    }

    .top-sellings .swiper-wrapper {

        transform: translate3d(0px, 0px, 0px) !important;

    }



    .top-sellings .swiper-button-prev, .top-sellings .swiper-button-next{

        display: none;

    }

    .top-sellings .swiper-slide-active {
        width: 100% !important;
    }

.floating--bar {

  z-index: 998 !important;
 margin-bottom:30px !important;
    }

 .top-sellings .swiper-horizontal > .swiper-pagination-bullets,.top-sellings .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, -6px) !important;
        top: var(--swiper-pagination-top, auto);
        left: 0;
        width: 100%;
    }

    .top-sellings .swiper-pagination-bullet {
        margin: 0 0.5rem !important;
    }


.cart {

   z-index: 980 !important;

    }

    #footer {
        padding-bottom: 4rem;
        padding-top: 4rem;
    }

    #footer .grid.grid-cols-1.md:grid-cols-4.gap-8 {
        gap: 1rem !important;
    }
    #footer .flex-col {
        text-align: center;
    }

    .df-c.\!gap-6.p-5.justify-around.pr.bg {
        padding: 1rem ;
    }

    .product-details {
        padding: 1rem;
    }

    .comments-upper {
        text-align: center;
    }

    .sm-items-center {
        align-items: center !important;
    }
    .sm-justify-center {
        justify-content: center !important;
    }
    
    .scrolable {
        width: 38vh;
    }

    .sgap-0 {
        gap: 0 !important;
    }


    #container form {
        padding: 0;
    }



    .product-details .df.w-fit.mx-auto {
        flex-wrap: wrap;
    }
    .comment-content {
        text-align: center;
    }

    .sm-mx-auto {
        margin: 0 auto !important;

    }

    .sm-w-fit {
        width: fit-content !important;
    }

    .sm-dis-inline-link {
        display: inline-block;
        margin: 0 auto !important;
        width: 100%;
    }

    .wrapper.\!mt-20 {
        margin: 1rem !important;
        max-width: unset;
        width: 100% !important;
    }

    .accordion {
        padding: 1rem 2.5rem;
        font-size: 15px;
        font-weight: bold;
    }
    .accordion p{
        max-width: 80%;
        text-align: right;
    }

    .phone-menu {
        display: flex
;
        width: 100%;
        position: fixed;
        bottom: 0;
        transform: 0 !important;
        z-index: 12;
        transform: unset !important;
        backdrop-filter: blur(15px);
        padding: 3px 5px;
        height: 5rem;
    }

    .phone-menu a {
        display: flex
;
        font-size: 10px;
        gap: 1px !important;
        aspect-ratio: 1;
        width: 4rem;

        
        border-radius: 7px;
        align-items: center;
        justify-content: center;
    }

    .floating--bar {
        bottom: 6rem !important;
    }
    .phone-menu a >  .ta-c{
        height: 0;
        color: white;
        opacity: 0;
        transition: 0.5s opacity;
    }

    .sm-mx-auto {
        margin: 0 auto;
    }
    .phone-menu a.active >  .ta-c{
        opacity: 1;
        height: auto;
    }
    
    .phone-menu a.active {
        background: var(--primary) !important;
        color: white;

    }

    .pannel p {
        text-align: justify;
    }

    .sm-ta-c {
        text-align: center;
    }

    .App {
        padding-top: 6rem;
        
    }

    .cart {
        width: 100% !important;
        height: 90vh !important;
        top: 10vh !important;
        transform: translate(0, -115%) !important;
    }

    .cart.sm-w-full.toggled {
        transform: translate(0, -0%) !important;
    }

    .main {
        padding: 0 1rem;
    }

    .sm-w-80 {
        width: 80%;
    }

    .products.px-12 {
        margin-top: 0 !important;
        padding-top: 1rem !important;
    }

    .products .title.w-fit.mx-auto.mt-12.pr {
        margin-top: 5px !important;
    }

    .sliderBlock {
        position: relative;
        height: 100%;
        margin-right: 0px !important;
        margin-bottom: 46px;
        overflow: hidden;}
    
    .productCard_block {
        width: 100% !important;
    }

    .block_product__advantagesProduct { 
        width: unset;
    }

    .block_descriptionInformation {
        width: unset;
    }
    .block_informationAboutDevice {
        position: relative;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .block_product {
        position: relative;
        width: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .productCard_rightSide {
        position: relative;
        padding: 34px 10px !important;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .accordion svg{
        font-size: 1.5rem;
    }
    .block_quantity {
        position: relative;
        margin-top: 45px;
        display: flex;
        justify-content: center;
    }

    .block_quantity__chooseBlock {
        width: unset;
    }
    .block_goodColor input{
        display: none;
    }

    .block_goodColor__allColors {
        display: flex;
        justify-content: center;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: center;
    }

    .styling__resp {
        width: 100%;
        justify-content: center;
    }
    .faq {
        width: 90%;
    }

    .sub-menu {
        width: 100% !important;
    }

    .checkbox-wrapper-4 .cbx {
        width: 70%;
        display: flex
;
        gap: 1rem;
    }

    .sm-fs {
        align-items: flex-start !important;
    }

    .sm-ai-fs {
        align-items: flex-start;
    }

    .sm-p-1 {
        padding: 0 1rem;
    }

    .sm-justify-sb {
        justify-content: space-between;
    }

    .sm-gap-2 {
        gap: 2rem !important;
    }

    .sm-text-center {
        text-align: center !important;
    }

    .prods {
        width: 100%;
        padding: 1rem 0rem;
        margin-top: 1rem;
    }

    .about {
        background: #fff8b7;
        padding: 5rem 6rem;
    }
    .w-3\/12.df.jc-sb.prods.cursor-pointer.pr.px-4.py-2 {
        height: 3rem !important;
        padding: 0 17px !important;
    }

    .space-y-2 li{
        text-align: center;
        justify-content: center;
        display: flex;
    }

    .top-sellings .swiper-button-prev {
        right: 2% !important;
    }
    .top-sellings .swiper-button-next {
        left: 2% !important;
    }

    .df-c.\!gap-20.ma.py-40.pr {
        padding: 4rem 1rem !important;
    }

    .profile-page .content {
        margin: unset;
    }

    .profile-page .content__button .button {
        display: inline-block;
        text-align: center;
        text-decoration: none;
        border-radius: 2rem;
        /* box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); */
        font-size: 1rem;
        color: #fff;
        cursor: pointer;
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .review-form {
        width: 100%;
    }

    .comments {
        width: 100%;
    }

    .container-padding.mt-40 {
        margin-top: 4rem !important;
    }

    .ad-sec .swiper {
        width: 100%;
        height: 80%;
    }

    .team .swiper-button-prev {
        right: 2% !important;
    }
    .team .swiper-button-next {
        left: 2% !important;
    }

    .log-tf-out {
        width: unset;
        height: unset;
        /* line-height: 2; */
        height: 100%;
        height: 2.5rem;
        display: grid;
        place-content: center;
    }

    .w-marg {
        width: 80% !important;
        margin : 0 auto !important
      }

      .padding-ha {
        padding-bottom: 1rem !important;
      }

    .sm-gapping {
        gap: 5px !important;
    }

    .option {
        width: 100%;
        margin-top: 1rem !important;
    }

    .hiddeny {
        bottom: -1rem;
    }


    .sm-df-c.mt-16{
        margin: 0 !important;
    }
    .products .page {
        margin: 3rem 0 0 0 ;
    }
    .products .page__numbers, .page__btn, .page__dots {
        margin: 3px;
    }

    .fdj {
        justify-content: space-around;
    }

    .sm-fl-1 {
        flex: 1;
    }

    .fill-jar .df.justify-between.w-full {
        justify-content: center;
    } 

    .honey-fill-instr {
        width: 60% !important;
        gap: 3rem !important;
    }

    .prop-cont {
        width: 1rem !important;
        margin-right: 0;
    }

    .fill-jar .spl,.fill-jar .spl2 {
        height: 6rem;
        max-width: unset;
        right: unset;
        left: unset;
        animation: 6rem;
        width: 112%;
        right: -31px;
    }

    .sb-wrap {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        height: fit-content;
    }

    .products {
        padding: 4rem 1rem;
    }

    .sm-df-c {
        flex-direction: column !important;
    }

    .sm-w-full {
        width: 100% !important;
    }

    .sm-g-3 {
        gap: 3rem !important;
    }

    .App {
        width: 100% !important;
        overflow-x: hidden;
    }

    .cart {
        width: 100%;
    }
    .container-padding {
        padding: 1rem;
    }

    .sw-100 {
        width: 100% !important;
    }


    body {
        background-color: var(--bg) !important;
    }



    .intro-hero {
        width: 100%;
        flex-direction: column;
    }
    .intro-hero .primary-title.pr.underlin {
        margin: 0 auto;
    }
    .intro-parag {
        width: 100%;
        text-align: center;
    }


    .product-cart {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: white;
    }
    .sub-bill {
        width: 100% !important;
        margin: 0;
    }
    .details-cart-prod > .df.mt-2 {
        justify-content: center;
    }

    #menu {
        width: 74% !important;
        margin-right: auto;
        margin-left: unset !important;
    }

    .holder {
        transform: translate(-50%, -50%);
    }
    .sdblock {
        display: block !important;
    }

    .profile-side-bar {
        position: fixed;
        background-color: white;
        z-index: 100;
        right: 0;
        width: 90%;
        transition: 0.5s;
        transform: translate(98%, -50%);
        top: 50%;
    }
    
    .p-sb {
        height: 4rem;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-100% ,-100%);
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        backdrop-filter: blur(22px);
    }

    .df-c.bg-white.p-8.\!gap-16 {
        width: 100%;
        padding: 1rem;
    }

    .adressbox {
        width: 100%;
    }

    .profile {
        margin-top: 2rem;
        background-color: #f3f4f6;
    }

    .hide-to-slide {
        display: block;
        height: 73%;
        width: 87%;
    }

    .dt-p .prop-cont img {
        transform: translateX(1.5rem) !important;
    }
    .hide-to-slide2 {
 
        display: block;
        height: 73%;
        width: 87%;
        bottom: 0;
        top: unset;
        height: 18%;
        width: 100%;
    }



    .p-5.rounded-lg {
        width: 100%;
    }

    .sm-p-0 {
        padding: 0;
    }

    .subheader {
        text-align: unset !important;
    }
    
    .trs {
        transform: translate(0%, -50%);
    }

    .animate-toggle-nav.p-sb .line1{
        stroke-dashoffset: -134;
        transform: rotate(-17deg) translateY(32px);
        transform-origin: center;
    }
    .animate-toggle-nav.p-sb .line3{
        stroke-dashoffset: -134;
        transform: rotate(17deg) translate(9px, -4px);
        transform-origin: center;
    }
}