
.loading-page {
    height: 100vh;
    background-color: var(--primary);
    direction: ltr;
    display: grid;
    place-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}
.error-page {
    height: 100vh;
    background-color: transparent;
    direction: ltr;
    display: grid;
    place-content: center;
}

.error-page .smile {
    transform: rotate(180deg);
    
}

  
  .beeLoader-container {
    position: relative;
    margin: 30px auto 0;
  }
  
  .wing-container {
    margin: 0px auto;
    width: 180px;
    height: 90px;
  }
  
  .leg-container {
    positon: relative;
    margin: auto;
    width: 180px;
  }
  
  .face-container {
    margin: auto;
    width: 288px;
    position: relative;
  }
  
  .error-page .beeLoader {
    margin: auto;
    height: 180px;
    width: 288px;
    background-color: var(--error);
    border-radius: 180px;
    border: solid 8px #000;
    overflow: hidden;
    position: relative;
  }
  .beeLoader {
    margin: auto;
    height: 180px;
    width: 288px;
    background-color: #ffdc46;
    border-radius: 180px;
    border: solid 8px #000;
    overflow: hidden;
    position: relative;
  }
  .beeLoader .shine {
    height: 20px;
    width: 100px;
    background-color: white;
    z-index: 7;
    border-radius: 10px;
    position: absolute;
    top: 23px;
    left: 123px;
  }
  
  .wing {
    height: 160px;
    width: 90px;
    position: relative;
    background-color: white;
    border-radius: 100px;
    border: 8px black solid;
    box-shadow: inset 0px 0px 0px 10px #aafaff;
  }
  .wing#wing1 {
    left: 20px;
  }
  .wing#wing2 {
    top: -170px;
    left: 45px;
    transform: rotate(45deg);
  }
  
  .error-page .shadow {
    background-color: var(--error);
    height: 90px;
    width: 288px;
    position: relative;
    top: 90px;
  }
  .shadow {
    background-color: #fdab3e;
    height: 90px;
    width: 288px;
    position: relative;
    top: 90px;
  }
  
  .eye {
    height: 16px;
    width: 14px;
    background-color: black;
    z-index: 5;
    position: absolute;
    border-radius: 35px;
    top: 60px;
}
  .eye#left {
    left: 18px;
  }
  .eye#right {
    left: 43px;
  }
  
  .leg {
    height: 20px;
    width: 60px;
    background-color: black;
    border-radius: 50px;
    position: relative;
    transform: rotate(45deg);
  }
  .leg#leg1 {
    left: 50px;
    top: 2px;
  }
  .leg#leg2 {
    left: 120px;
    top: -18px;
  }
  
  .smile {
    position: absolute;
    width: 20px;
    height: 10px;
    border-bottom-left-radius: 110px;
    border-bottom-right-radius: 110px;
    border: 7px solid black;
    border-top: 0;
    top: 6rem;
    z-index: 5;
    left: 2rem;
  }
  
  .error-page .stripe {
    position: absolute;
    display: inline-flex;
    width: 50px;
    height: 180px;
    background-color: black;
    top: 0;
    animation-duration: 5s;
    animation-name: none;
    transition: ease-in-out;
    -webkit-animation-fill-mode: none;
    animation-iteration-count: none;
  }
  .stripe {
    position: absolute;
    display: inline-flex;
    width: 50px;
    height: 180px;
    background-color: black;
    top: 0;
    animation-duration: 5s;
    animation-name: dot;
    transition: ease-in-out;
    -webkit-animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
  .stripe#one {
    margin-left: 100px;
  }
  .stripe#two {
    animation-delay: 0.8s;
    margin-left: 170px;
  }
  .stripe#three {
    animation-delay: 1.6s;
    margin-left: 240px;
  }
  
  @keyframes dot {
    16% {
      top: 0px;
      height: 180px;
      border-radius: 0;
    }
    33% {
      top: 67px;
      height: 50px;
      border-radius: 50%;
    }
    66% {
      top: 67px;
      height: 50px;
      border-radius: 50%;
    }
    83% {
      top: 0px;
      height: 180px;
      border-radius: 0;
    }
  }