kbd {
    background: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 1px 1px #00000028, 0 2px 0 0 #ffffffc3 inset;
    color: #131313;
    display: inline-block;
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
    margin: auto;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #7c7b7b54;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

}

.modal {
    width: fit-content;
    margin: 32px;
    overflow-y: auto;

    margin: auto;
    padding: 0 2rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    box-shadow: var(--shadow);
}

.modal::-webkit-scrollbar {
    display: none;
}

.payment-label {
    border: 2px solid #e4e4e4;
    border-radius: 1rem;
    padding: 1rem;
    width: 12rem;
    align-items: center;
}

.payment-label img {
    border-radius: 7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.modal-button {
    position: relative;
    bottom: 1.5rem;
    padding: 0 3rem;
    min-height: 3rem;
    margin: auto auto 0 auto;
    background: var(--dark);
    color: var(--light);
}

.close-button {
    padding: 0 2rem;
    height: 2.5rem;
    margin: 2rem auto 1rem 0;
    background: #101111;
    /* border: 1px dashed #9a9a9a99; */
    color: #ffaa00;
    border-radius: 4px;
    transition: background ease 400ms;
    box-shadow: 1px 1px 15px #03030399;
}

.adressmodal {
    height: 90vh;
    overflow-y: scroll;
    justify-content: flex-start !important;
}

.adressmodal label {
    color: var(--canceled);
    font-weight: bold;
}

.add {
    color: var(--text);
    transition: 0.5s;
}
.add:hover {
    color: var(--primary);
    cursor: pointer;
}







.bottom {
    justify-content: flex-end;
}

.top {
    justify-content: flex-start;
}

.notification-text {
    margin: auto auto auto 0;
    padding: 0;
    font-size: 100%;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}


.notif-height {
    height: 67vh !important;
}

.unseen {
    background: var(--cyan-light);
    position: relative;
    border-radius: 1rem; 
}
.unseen::after {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--cyan);
    left: 1rem;
    top: 1rem;
}

.add-button {
    padding: 0 1rem;
    margin: 2rem auto auto 0;
    background: var(--gradient2);
    color: var(--dark);
}

.close {
    height: 1.1rem;
    background: transparent;
    border: none;
    outline: none;
    margin: 0 0 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close svg {
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.sub-header {
    margin: 1rem auto 1rem 0;
    color: #9e9e9e;
}

@media screen and (min-width: 960px) {
    
}

.modal {
    background-color: var(--light-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

}

.ai-center {
    align-items: center;
}

.warning-icon {
    color: var(--primary);
    font-size: 5rem;
}

.notfication-popup {
    width: 30rem;
    height: 4rem;
    position: fixed;
    border-radius: 1rem;
    padding: 1rem;
    right: 3rem;
    bottom: 3rem;
    z-index: 100;
}

.flex-1 {
    flex: 1;
}

.w-50 {
    width: 50% !important;
}

.bord {
    border-radius: 1rem;
    padding: 1rem ;
    justify-content: space-between !important;
}

.notifications-modal {
    color: var(--text) !important;
    padding: 0rem !important;
    width: 55vh !important;
    margin: 0 !important;
    position: absolute;
    top: right;
    right: 16%;
    top: 11%;
    border: 1px solid #d3d3d3;
    }

.withdraw-modal {
    width: 90% !important;
    background: var(--light-bg);
    border-radius: 1rem;
    padding: 1rem 3rem ;
}

.selected {
    border: 2px solid var(--success) !important;
}

.payment-label {
    border: 2px solid #e4e4e4;
    border-radius: 1rem;
    padding: 1rem;
}
.payment-label span{
    color: var(--text);
}
.selected span{
    color: var(--success) !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    display: flex;
    align-items: center;
    gap: 1rem;
}




.total-salary {
    padding: 2rem 1rem;
    border-radius: 1rem;
    background-color: var(--purple-light);
}

.border-b {
    border-bottom: 1px solid #e0e0e0;
}

.modal .title {
    color: #262b43e6;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.58334;
}

/* ============================== */

input[type="radio"] {
    --s: 1em; /* Size of the radio button */
  
    aspect-ratio: 1; /* Ensures the height and width are equal */
    border: calc(var(--s) / 8) solid #939393; /* Border size */
    padding: calc(var(--s) / 8); /* Padding */
    width: 20px;
    height: 20px;
    margin: 0 auto;
    background: radial-gradient(farthest-side, var(--canceled) 94%, transparent) 50%/0 0 no-repeat content-box;
    border-radius: 50%; /* Circular shape */
    outline-offset: calc(var(--s) / 10);
    appearance: none; /* Removes default appearance */
    cursor: pointer;
    font-size: inherit;
    transition: 0.3s ease; /* Smooth transition */
  }
  
  input[type="radio"]:checked {
    border-color: var(--success); /* Border changes to active color */
    background-size: 100% 100%; /* Expands the background */
  }
  
  input[type="radio"]:disabled {
    background: linear-gradient(#939393 0 0) 50%/100% 20% no-repeat content-box;
    opacity: 0.5; /* Makes it semi-transparent */
    cursor: not-allowed; /* Changes cursor when disabled */
  }