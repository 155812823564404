.partners .swiper {
    width: 100%;
    height: 100%;
    height: 10rem;
    padding: 2rem 3rem;
    border-radius: 1rem;
    
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
}

.partners .swiper-button-prev:after,.partners .swiper-button-next:after {
  font-size: 1rem !important;
  color: var(--primary);
  font-weight: bold;
}

.partners .swiper-button-prev, .partners .swiper-button-next {
  border: 2px solid var(--primary);
  border-radius: 50%;
  height: 2rem;
  width: 2rem;


}

 .partners .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .partners .swiper-slide img {
      display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hide-to-slide {
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  background: transparent;
  z-index: 10; /* Above Swiper content but below pagination/navigation */
  pointer-events: all;
  display: none;
}
.hide-to-slide2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  background: transparent;
  z-index: 10; /* Above Swiper content but below pagination/navigation */
  pointer-events: all;
  display: none;
}



.ad-sec .swiper-button-next {
  top: 72%;
  right: unset;
  transform: rotate(-90deg);
  font-size: 15px !important;
  width: 2rem;
  height: 2rem;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  left: 0.4%;
  align-items: center;
  justify-content: center;
}
.ad-sec .swiper-button-next:after, .ad-sec .swiper-button-prev:after {
  font-size: 15px !important;
  font-weight: 900;
  color: white;
}

.ad-sec .swiper-button-prev {
  width: 2rem;
  height: 2rem;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0.4%;
  top: 28%;
  right: unset;
  transform: rotate(-90deg);
  font-size: 15px !important;
}

.our-part-cont {
  padding: 0 1rem;
  border-radius: 1rem;
  border: 2px solid var(--primary);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  overflow: hidden;
    position: relative; 
    width: 90%;


}

