.reviews {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
}

.reviews .review-line-container {
    position: relative;
    width: 7rem;
    height: 8px;
    border-radius: 7px;
    overflow: hidden;
    background-color: var(--light-primary);
    
}

.avg-star-nbr span{
    line-height: 1;
    font-size: 14px;
    font-weight: bold;
}

.nbr-indic {
    position: relative;
    
}
.nbr-indic::before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px ;
    border-radius: 50%;
    background-color: #c6c6c6b3;
    right: -1.5rem;
    top: 1px;

}

.rev-cont {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.ai-fe {
    align-items: flex-end !important;
}

.review-line-container .line {
    background-color: var(--pending);
    height: 100%;
}

.comment-user-img img {
    width: 4rem;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 7px;
}

.filtres {
    color: var(--canceled);
}

.comment-user-img {
    font-size: 10px;
    min-width: 30%;
}

.comment {
    border-bottom: 1px solid #838383b3;
    padding: 3rem 0;
}

.comments {
    width: 70%;
}

.comments-upper {
    
    width: 70%;
    border-bottom: 1px solid #838383b3;
    padding-bottom: 4rem;
}

svg.star-svg {
    display: inline;
}

.review-form {
    width: 70%;
    padding: 4rem 0;
    border-bottom: 1px solid #838383b3;
    text-align: center;

}

textarea {
    background: transparent;
    padding: 5px;
    outline: none;
    border-radius: 5px;
    border: 2px solid #b8b8b8;
    resize: none;
    height: 6rem;
}

